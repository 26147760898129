import './ProductBasket.css';
import React, { useEffect, useState, useCallback, } from 'react';
import { Link, useParams, } from 'react-router-dom';
import request from '../request';
import config from '../config';
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import basket from '../basket';
import Select, { StylesConfig } from 'react-select';
import makeAnimated from 'react-select/animated';
import gtag_evt from '../gtag';
import storage from "../storage";




class ProductBasket extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      variants: props.product.variants.sort( (a, b) => {
        if(a.attributes[0].value > b.attributes[0].value){
          return 1;
        }
        if(a.attributes[0].value == b.attributes[0].value){
          return 0;
        }
        if(a.attributes[0].value < b.attributes[0].value){
          return -1;
        }
      }),
      selected_variants: [],
      total_qty: 0,
      prices: props.product.prices,
      current_price_set: props.product.prices ? props.product.prices[0] : false,
      product: props.product,
      logged_in: false,
      selected_color: [],
      selected_size: [],
      showToast: false,
      showBasketToast: false,
      passive_discounts: props.product.passive_discounts,
      active_discounts: props.product.active_discounts,
      selected_discounts: [],
    };

    this.elementRef = React.createRef();
  }

  componentDidMount() {
    const get_logged_in = async () => {
      const r = await request.get_user_status();
      const is_logged_in  = await r;
      
      this.setState({
        ...this.state,
        logged_in: is_logged_in
      });
    };
    get_logged_in()

    const get_basket_products = () => {
      let vars = this.state.variants;
      let selected_vars = this.state.selected_variants;
      let localstorageProducts = localStorage.getItem('basket');
      let r;
      if (localstorageProducts) {
        r = JSON.parse(localstorageProducts);
      }else{
        return;
      }
      r.forEach( (p) => {

        if(p.id == this.state.product.id){
          vars.map( (v) => {
            if(v.id == p.selectedVariant.id){
              v.selected_qty = p.quantity;
              selected_vars[v.id] = p.quantity;
            }
          })
        }
      })
      
      this.setState({
        ...this.state,
        variants: vars,
        selected_variants: selected_vars
      }, () => {
        this.calculateTotal()
      });
    };
    get_basket_products()


    this.forceUpdate();
    
  }

  changeQty(qty, id){
    let vars = this.state.variants;
    let selected_vars = this.state.selected_variants;
    let total = this.state.total_qty;
    
    vars.map( (e) => {
      if(e.id === id){
        if(e.selected_qty)
          e.selected_qty += qty;
        else
          e.selected_qty = qty;

        
      }
      
      if(e.selected_qty <= 0){
        e.selected_qty = 0;
        
        delete(selected_vars[e.id])
      }

      if(e.selected_qty > this.calcMaxQty(e) ){
        e.selected_qty = this.calcMaxQty(e);
        
      }
      if(e.selected_qty > 0){
        selected_vars[e.id] = e.selected_qty;
      }
    });
    this.setState({
      ...this.state,
      variants: vars,
      selected_variants: selected_vars,
    }, () => {this.calculateTotal(); })

  }

  // setDiscountsQtys(selected_vars, selected_discounts) {
  //   let passive_discounts = this.state.passive_discounts;
  //   selected_discounts = []
  //   if(passive_discounts){
  //     passive_discounts.map( (d) => d.current_qty = 0)
  //     selected_vars.forEach( (qty, id) => {
  //       console.log(qty, id)
  //       if(typeof passive_discounts.find( (d) => {return d.variants.indexOf(String(id)) > -1}) != "undefined"){
  //         let qty_before = passive_discounts.find( (d) => {return d.variants.indexOf(String(id)) > -1}).current_qty;
  //         passive_discounts.find( (d) => {return d.variants.indexOf(String(id)) > -1}).current_qty += qty
  //         let discount = passive_discounts.find( (d) => {return d.variants.indexOf(String(id)) > -1})
  //         if(discount.current_qty <= discount.qty){
  //           selected_discounts[id] = qty;
  //         }else if(qty_before < discount.qty){
  //           selected_discounts[id] = discount.qty - qty_before;
  //         }
  //       }
  //     });
  //     selected_discounts.forEach( (d, id) => {
  //         if(selected_vars[id]){
  //           if(selected_vars[id] > d){
  //             selected_vars[id] -= d
              
  //           }else{
  //             delete(selected_vars[id])

  //           }
  //         }
  //     });
  //     //console.log(selected_discounts)
  //   }
  //   return selected_discounts;
    
  // }
  calcMaxQty(variant){
    const pt_qty = parseInt(variant.pt_qty) > 0 ? parseInt(variant.pt_qty) : 0; 
    let max = parseInt(variant.qty) + parseInt(variant.expected_week_qty) + parseInt(variant.expected_month_qty) + pt_qty
    max = max > 0 ? max : 0
    return max
  }
  changeAbsQty(evt, id) {
    let vars = this.state.variants;
    let selected_vars = this.state.selected_variants;
    let new_qty = evt.target.value;
    let total = this.state.total_qty;

    if(new_qty.substring(0,1) == "0"){
      new_qty = new_qty.substring(1);
      if(new_qty == ""){
        new_qty = 0;
      }
    }
    if(new_qty == 0){
      delete(selected_vars[id])
    }

    vars.map( (e) => {
      if(e.id === id){
        if( e.selected_qty == 0){
          e.selected_qty += parseInt(new_qty);
        }else{
          e.selected_qty = parseInt(new_qty);
        }
      }
      if(e.selected_qty <= 0){
        e.selected_qty = 0;
        
      }

      if(e.selected_qty > this.calcMaxQty(e) ){
        e.selected_qty = this.calcMaxQty(e);
        
      }
        

      if(e.selected_qty > 0){
        selected_vars[e.id] = e.selected_qty;
      }else{
        delete(selected_vars[e.id])
      }
    });

    // selected_vars = this.calculatePicking(selected_vars);

    this.setState({
      ...this.state,
      variants: vars,
      selected_variants: selected_vars
    }, () => {this.calculateTotal()})
    
  }

  calculateTotal() {
    let total = 0;
    this.state.selected_variants.forEach( (v) => {
      total += v;
    })

    this.setState({
      ...this.state,
      total_qty: total
    }, () => {this.showToast()})
  }

  basket_timer = () => setTimeout(() => { // return the timeoutID
    this.setState({
      ...this.state,
      showBasketToast: false,
    });

  }, 5000);

  timer = () => setTimeout(() => { // return the timeoutID
    this.setState({
      ...this.state,
      showToast: false,
    });

    this.forceUpdate();
  }, 5000);

  showToast() {

    let current_price = this.state.current_price_set;
    let new_state = false;

    if(this.state.prices && this.state.prices.length > 0){
      let valid_prices = this.state.prices.filter( (p) => {
        return parseInt(p.qty) <= this.state.total_qty
      })
      if(valid_prices.length > 0){
        let single_price = valid_prices.reduce(function (p, v) {
          return ( parseInt(p.qty) > parseInt(v.qty) ? p : v );
        });
        current_price = single_price;
      }

      if(this.state.current_price_set && current_price.price != this.state.current_price_set.price){
        clearTimeout(this.timeoutID)
        this.props.onPriceSetChange(current_price)
        this.setState({
          ...this.state,
          showToast: true,
          current_price_set: current_price
        }, () => {});
        new_state = true;
    
        this.timeoutID = this.timer()
      }
    }
    if(!new_state){
      this.props.onPriceSetChange(current_price)
      this.setState({
        ...this.state,
        current_price_set: current_price
      }, () => {});
    }
  }

  closeToast() {
    this.setState({
      ...this.state,
      showToast: false,
    });
  }

  closeBasketToast() {
    this.setState({
      ...this.state,
      showBasketToast: false,
    });
  }

  calculatePrice() {
    if(this.state.prices && this.state.prices.length > 0){
      let valid_prices = this.state.prices.filter( (p) => {
        return parseInt(p.qty) <= this.state.total_qty
      })
      if(valid_prices.length > 0){
        let single_price = valid_prices.reduce(function (p, v) {
          return ( parseInt(p.qty) > parseInt(v.qty) ? p : v );
        });
        return (parseFloat((single_price.price * this.state.total_qty).toFixed(2)) + parseFloat(this.calculatePickingPrice()) ).toFixed(2)
      }else{
        return 0;
      }
    }else{
      return (parseFloat((this.state.product.price * this.state.total_qty).toFixed(2)) + parseFloat(this.calculatePickingPrice()) ).toFixed(2) ;
    }
  }

  // calculatePicking(selected_vars) {
  //   if(this.state.product.picking_package && this.state.product.picking_package > 0 && this.state.product.picking_price && this.state.product.picking_price > 0){
  //     selected_vars.forEach( (v) => {
        
  //     });
  //   }

  //   return selected_vars;
    
  // }

  getColors() {
      let colors = [];
      let has_colors = [];
      this.state.variants.map( (variant) => {
        variant.attributes.map( (attribute) => {
          
          
          if(attribute.attribute_category_id == 1 && has_colors.indexOf(attribute.value_id) === -1){
            let opt = {value: attribute.value_id, label: attribute.value }
            colors.push(opt)
            has_colors.push(attribute.value_id)
          }
          
        })
      } )
      
      return colors;
    
  }

  getSizes() {
    let sizes = [];
      let has_sizes = [];
      this.state.variants.map( (variant) => {
        variant.attributes.map( (attribute) => {
          
          
          if(attribute.attribute_category_id == 2 && has_sizes.indexOf(attribute.value_id) === -1){
            let opt = {value: attribute.value_id, label: attribute.value }
            sizes.push(opt)
            has_sizes.push(attribute.value_id)
          }
          
        })
      } )
      
      return sizes;
  }

  filterColors(e) {
    let colors = [];
    e.forEach( (a) => { colors.push(a.value)})

    this.setState({
      ...this.state,
      selected_color: colors
    }, () => {
      this.filterVariables()
    })
  }

  filterSizes(e) {
    let sizes = [];
    e.forEach( (a) => { sizes.push(a.value)})

    this.setState({
      ...this.state,
      selected_size: sizes
    }, () => {
      this.filterVariables()
    })
  }

  filterVariables() {
    let colors = this.state.selected_color;
    let sizes = this.state.selected_size;
    let vars = this.state.variants;
    
    vars.map( (v) => {
      let has_attribute = v.attributes.find( (a) => (colors.indexOf(a.value_id) > -1 || colors.length == 0))
      if( typeof has_attribute == "undefined" ){
        v.display = "none";
      }else{
        let has_attribute = v.attributes.find( (a) => (sizes.indexOf(a.value_id) > -1 || sizes.length == 0))
        if( typeof has_attribute == "undefined" ){
          v.display = "none";
        }else{
          v.display = "table-row";
        }
      }
    });

    this.setState({
      ...this.state,
      variants: vars
    });

  }

  sortVariables(pos, e) {
    let vars = this.state.variants;
    let order = e.target.getAttribute("data-order");
    
    let bigger = -1;
    let smaller = 1;
    if(order == "asc"){
      bigger = 1;
      smaller = -1;

      e.target.setAttribute("data-order", "desc");
    }else{
      e.target.setAttribute("data-order", "asc");
    }
    
    if(pos == 1 || pos == 2){
      let attr_pos = pos - 1;
      vars.sort( (a, b) => {
        if(a.attributes[attr_pos].value > b.attributes[attr_pos].value){
          return bigger;
        }
        if(a.attributes[attr_pos].value == b.attributes[attr_pos].value){
          return 0;
        }
        if(a.attributes[attr_pos].value < b.attributes[attr_pos].value){
          return smaller;
        }
      })
    }else if(pos == 3){
      vars.sort( (a, b) => {
        if(!a.selected_qty){
          return smaller;
        }
        if(!b.selected_qty){
          return bigger;
        }
        if(parseInt(a.selected_qty) > parseInt(b.selected_qty)){
          return bigger;
        }
        if(parseInt(a.selected_qty) == parseInt(b.selected_qty)){
          return 0;
        }
        if(parseInt(a.selected_qty) < parseInt(b.selected_qty)){
          return smaller;
        }
      })
    }else if(pos == 4){
      vars.sort( (a, b) => {
        if(parseInt(a.qty) > parseInt(b.qty)){
          return bigger;
        }
        if(parseInt(a.qty) == parseInt(b.qty)){
          return 0;
        }
        if(parseInt(a.qty) < parseInt(b.qty)){
          return smaller;
        }
      })
    }else if(pos == 5){
      vars.sort( (a, b) => {
        if(parseInt(a.expected_week_qty) > parseInt(b.expected_week_qty)){
          return bigger;
        }
        if(parseInt(a.expected_week_qty) == parseInt(b.expected_week_qty)){
          return 0;
        }
        if(parseInt(a.expected_week_qty) < parseInt(b.expected_week_qty)){
          return smaller;
        }
      })
    }else if(pos == 6){
      vars.sort( (a, b) => {
        if(parseInt(a.expected_month_qty) > parseInt(b.expected_month_qty)){
          return bigger;
        }
        if(parseInt(a.expected_month_qty) == parseInt(b.expected_month_qty)){
          return 0;
        }
        if(parseInt(a.expected_month_qty) < parseInt(b.expected_month_qty)){
          return smaller;
        }
      })
    }else if(pos == 7){
      vars.sort( (a, b) => {
        if(parseInt(a.expected_later_qty) > parseInt(b.expected_later_qty)){
          return bigger;
        }
        if(parseInt(a.expected_later_qty) == parseInt(b.expected_later_qty)){
          return 0;
        }
        if(parseInt(a.expected_later_qty) < parseInt(b.expected_later_qty)){
          return smaller;
        }
      })
    }

    this.setState({
      ...this.state,
      variants: vars
    });
  }
  
  addToBasket() {

    let selected_vars = this.state.selected_variants;
    let product = this.state.product;
    if(selected_vars.length > 0){
      selected_vars.forEach( (v, index) => {
        product.selectedVariant = this.state.variants.filter(function (p) {
          return ( p.id == index );
        })[0];

        basket.addProduct(product, v)
      } )

      if(this.state.product.active_discounts && this.state.product.active_discounts.discount) {
        if(this.state.total_qty >= this.state.product.active_discounts.discount.qty){
          this.props.onDiscountTrigger({
            show: true,
            total_qty: this.state.total_qty
          })
        }else {
          this.props.onDiscountTrigger({show: false})
        }
      }
      gtag_evt.gtag('event', 'add_to_cart', {
        currency: 'EUR',
        items: [{
          item_id: this.state.product.code,
          item_name: this.state.product.name,
          item_brand: this.state.product.manufacturer,
          item_category: null,
          price: this.calculatePrice(),
          currency: 'EUR',
          quantity: this.state.total_qty
        }],
        value: this.calculatePrice()
      })
    }
    if(this.state.variants.length > 0){
      this.state.variants.filter((a) => typeof selected_vars[a.id] == 'undefined' || typeof selected_vars[a.id] == 'null').forEach( (v, index) => {
        basket.removeProduct(product, v.id)
      } )
    }

    clearTimeout(this.timeoutBasket)

    this.setState({
      ...this.state,
      showBasketToast: true,
    });

    this.timeoutBasket = this.basket_timer()
  }

  calculateColorQty(variant_id){
    let qty = 0;
    let color = this.state.variants.filter( (v) => {return v.id == variant_id} )[0].attributes.filter( (a) => {return a.attribute_category_id == 1})[0].value_id;
    let color_variants = this.state.variants.filter( (v) => {
      return v.attributes.filter( (a) => {return a.attribute_category_id == 1 && a.value_id == color}).length > 0;
    } )
    this.state.selected_variants.forEach( (q, i) => {
      if(color_variants.filter( (c) => {return c.id == i} ).length > 0){
        qty += parseInt(q)
      }
    } )
    return qty
  }

  getPicking(variant_id){
    if (!config.picking) return 0; 
    let price = 0;
    if(this.state.product.picking_package && this.state.product.picking_package > 0 && this.state.product.picking_price && this.state.product.picking_price > 0 && this.calculateColorQty(variant_id) < this.state.product.picking_limit){
      price = ((this.state.selected_variants[variant_id] % this.state.product.picking_package) * this.state.product.picking_price).toFixed(2)
    }

    return price
  }

  calculatePickingPrice(){
    if (!config.picking) return 0; 
    let price = 0;
    if(this.state.product.picking_package && this.state.product.picking_package > 0 && this.state.product.picking_price && this.state.product.picking_price > 0){
      this.state.selected_variants.forEach( (qty, i) => {
        if(this.calculateColorQty(i) < this.state.product.picking_limit){
          price += parseFloat(((qty % this.state.product.picking_package) * this.state.product.picking_price).toFixed(2))
        }
      })
    }
    return price.toFixed(2)
  }

  render(){
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        background: state.isFocused ? "#8e8e8e" : 'none',
        borderBottom: '1px solid #5E5E5E',
        color: '#FFF',
        padding: 10,
        "&:hover": {
          background: "#8e8e8e",
        }
      }),
      
      control: base => ({
        ...base,
        "&:hover, &:active, &:target ": {
          borderColor: "red",
          color: "red"
        },
        color: "#fff",
        minWidth: "200px",
      }),
      input: (provided, state) => ({
        ...provided,
        color: '#FFF',
        placeholder: i18n.t("choose_color")
      }),
      placeholder: (provided, state) => ({
        ...provided,
        color: '#FFF',
      }),
      dropdownIndicator: base => ({
        ...base,
        color: "inherit"
      }),
      singleValue: base => ({
        ...base,
        color: "inherit"
      })
    }
    
    let animatedComponents  = makeAnimated();
    return this.state.logged_in ? 
     (
         <div className="variants-basket" style={storage.me.seller_mode ? {marginTop: '50px'} : {}}>
           <div className="variants-header-wrapper" style={storage.me.seller_mode ? {width: '100%'} : {}}>
             <table className="variants-header">
               <thead>
               <tr>
                 <th colSpan="8" width="100%" className="table-price-border-right">{i18n.t("select_quantities")}</th>
               </tr>
               <tr>
                 <th colSpan="2">
                   <Select
                       styles={customStyles}
                       closeMenuOnSelect={false}
                       components={animatedComponents}

                       placeholder={i18n.t("choose_color")}
                       isMulti
                       className="filterVariants"
                       onChange={(e) => this.filterColors(e)}
                       options={
                         this.getColors()
                       }
                   >

                   </Select>
                 </th>
                 <th colSpan="2">
                   <Select
                       styles={customStyles}
                       closeMenuOnSelect={false}
                       components={animatedComponents}
                       placeholder={i18n.t("choose_size")}
                       isMulti
                       className="filterVariants"
                       onChange={(e) => this.filterSizes(e)}
                       options={
                         this.getSizes()
                       }
                   >
                   </Select>
                 </th>
                 <th>{i18n.t("filters")}</th>
                 <th></th>
                 <th></th>
                 <th></th>
               </tr>
               <tr>
                 <th onClick={(e) => this.sortVariables(1, e)} data-order="asc"
                     style={{width: "8%"}}>{i18n.t("color")}</th>
                 <th onClick={(e) => this.sortVariables(2, e)} data-order="asc"
                     style={{width: "8%"}}>{i18n.t("size")}</th>
                 <th style={{width: "10%"}}>{i18n.t("order_qtys")}</th>
                 <th style={{width: "8%"}}>{i18n.t("available_qtys")}</th>
                 <th style={{width: "8%"}}>{i18n.t("expected_week")}</th>
                 <th style={{width: "8%"}}>{i18n.t("expected_month")}</th>
                 <th style={{width: "8%"}}>{i18n.t("expected_later")}</th>
                 <th style={{width: "8%"}}>{i18n.t("pt_qtys")}</th>
               </tr>
               </thead>
               <tbody>
               {
                   this.state.variants &&
                   this.state.variants.map((variant, index) => {
                     let variant_reduce = 0;
                     let expected_week = variant.expected_week_qty;
                     let expected_month = variant.expected_month_qty;
                     let expected_later = variant.expected_later_qty;

                     let pt_qty = variant.pt_qty > 0 ? variant.pt_qty : 0;
                     if (variant.qty < 0) {
                       variant_reduce = -variant.qty;
                       if (variant_reduce > expected_week) {
                         variant_reduce = variant_reduce - expected_week
                         expected_week = 0;
                       } else {
                         expected_week -= variant_reduce;
                         variant_reduce = 0;
                       }

                       if (variant_reduce > 0 && variant_reduce > expected_month) {
                         variant_reduce = variant_reduce - expected_month
                         expected_month = 0;
                       } else {
                         expected_month -= variant_reduce;
                         variant_reduce = 0;
                       }

                       if (variant_reduce > 0 && variant_reduce > expected_later) {
                         variant_reduce = variant_reduce - expected_later
                         expected_later = 0;
                       } else {
                         expected_later -= variant_reduce;
                         variant_reduce = 0;
                       }

                       variant_reduce = -variant.qty;
                       let week_po = variant.arrivals.filter((a) => {
                         return a.arrival_type == 1
                       }).map((a) => {
                         if (typeof a.reduced == "undefined") {
                           if (variant_reduce > 0 && variant_reduce > a.arrival_qty) {
                             variant_reduce = variant_reduce - a.arrival_qty
                             a.arrival_qty = 0;
                           } else {
                             a.arrival_qty -= variant_reduce;
                             variant_reduce = 0;
                           }
                           a.reduced = true;
                         }

                       })
                       let month_po = variant.arrivals.filter((a) => {
                         return a.arrival_type == 2
                       }).map((a) => {
                         if (typeof a.reduced == "undefined") {
                           if (variant_reduce > 0 && variant_reduce > a.arrival_qty) {
                             variant_reduce = variant_reduce - a.arrival_qty
                             a.arrival_qty = 0;
                           } else {
                             a.arrival_qty -= variant_reduce;
                             variant_reduce = 0;
                           }
                           a.reduced = true;
                         }
                       })
                       let later_po = variant.arrivals.filter((a) => {
                         return a.arrival_type == 3
                       }).map((a) => {
                         if (typeof a.reduced == "undefined") {
                           if (variant_reduce > 0 && variant_reduce > a.arrival_qty) {
                             variant_reduce = variant_reduce - a.arrival_qty
                             a.arrival_qty = 0;
                           } else {
                             a.arrival_qty -= variant_reduce;
                             variant_reduce = 0;
                           }
                           a.reduced = true;
                         }
                       })

                       //variant.arrivals = week_po.concat(month_po,later_po)
                     }
                     return <tr key={variant.id} className={`variant-basket variant-${variant.id}`}
                                style={{display: variant.display ? variant.display : ''}}>
                       {
                         variant.attributes.map((attribute, i) => {
                           let grad;
                           if (attribute.attribute_category_id === "1" && attribute.custom_value && attribute.custom_value !== "") {
                             if (attribute.custom_value.indexOf("|") > -1) {
                               let colors = attribute.custom_value.split("|");
                               let percentages = [];

                               let percent = Math.floor(100 / colors.length);
                               grad = 'linear-gradient(45deg';
                               colors.forEach((c, i) => {
                                 if (colors.length > 1) {
                                   if (i == 0) {
                                     grad += ', #' + c + " " + (percent + Math.floor(percent / 3)) + "%";
                                   } else {
                                     grad += ', #' + c + " " + (percent - Math.floor(percent / (colors.length + 1))) + "%";
                                   }
                                 } else {

                                 }
                                 grad += ', #' + c + " " + percent + "%";
                               })
                               grad += ')';
                             } else {
                               grad = "#" + attribute.custom_value;
                             }
                           }
                           return <td key={i} className="attribute-wrapper">
                                      <span>
                                      {attribute.attribute_category_id === "1" && attribute.custom_value && attribute.custom_value !== "" &&
                                          <b style={{
                                            display: 'inline-block',
                                            width: '15px',
                                            height: '15px',
                                            background: grad,
                                            margin: 'auto',
                                            marginRight: '10px'
                                          }}>

                                          </b>
                                      }
                                        {attribute.value}
                                      </span>
                           </td>
                         })
                       }
                       { ! storage.me.seller_mode && ! storage.me.seller_price_mode ?
                       <td>
                         <button className="custom-stepper-btn minus-stepper"
                                 onClick={() => this.changeQty(-10, variant.id)}>-10
                         </button>
                         <button className="custom-stepper-btn minus-stepper"
                                 onClick={() => this.changeQty(-1, variant.id)}>-
                         </button>
                         <input type="number" className="variant-qty custom-stepper" min="0" key={variant.id}
                                onChange={(e) => this.changeAbsQty(e, variant.id)}
                                value={variant.selected_qty ? variant.selected_qty.toString() : 0}/>
                         <button className="custom-stepper-btn plus-stepper"
                                 onClick={() => this.changeQty(1, variant.id)}>+
                         </button>
                         <button className="custom-stepper-btn plus-stepper"
                                 onClick={() => this.changeQty(10, variant.id)}>+10
                         </button>
                       </td> : <td style={{height: '35px'}}></td>}
                       <td><span>{variant.qty > 0 ? variant.qty : 0} {i18n.t("qty_metric")}</span></td>
                       <td style={{textAlign: "center", position: "relative"}}>

                         {expected_week} {i18n.t("qty_metric")}
                         <FontAwesomeIcon
                             icon={faInfoCircle}
                             size="sm"
                             className={`${expected_week > 0 ? 'view-more' : 'view-more-disabled'}`}
                         />
                         <div className="more-qtys">
                           {
                             variant.arrivals.filter((a) => {
                               return a.arrival_type == 1
                             }).map((a) => {
                               if (a.arrival_qty > 0)
                                 return <div className="qty-row">
                                   <span>{a.date}</span>
                                   <span>{a.arrival_qty} {i18n.t("qty_metric")}</span>
                                 </div>
                               else return ''
                             })
                           }
                         </div>
                       </td>
                       <td style={{textAlign: "center", position: "relative"}}>

                         {expected_month} {i18n.t("qty_metric")}
                         <FontAwesomeIcon
                             icon={faInfoCircle}
                             size="sm"
                             className={`${expected_month > 0 ? 'view-more' : 'view-more-disabled'}`}
                         />
                         <div className="more-qtys">
                           {
                             variant.arrivals.filter((a) => {
                               return a.arrival_type == 2
                             }).map((a) => {
                               if (a.arrival_qty > 0)
                                 return <div className="qty-row">
                                   <span>{a.date}</span>
                                   <span>{a.arrival_qty} {i18n.t("qty_metric")}</span>
                                 </div>
                               else return ''
                             })
                           }
                         </div>
                       </td>
                       <td style={{textAlign: "center", position: "relative"}}>

                         {expected_later} {i18n.t("qty_metric")}
                         <FontAwesomeIcon
                             icon={faInfoCircle}
                             size="sm"
                             className={`${expected_later > 0 ? 'view-more' : 'view-more-disabled'}`}
                         />
                         <div className="more-qtys">
                           {
                             variant.arrivals.filter((a) => {
                               return a.arrival_type == 3
                             }).map((a) => {
                               if (a.arrival_qty > 0)
                                 return <div className="qty-row">
                                   <span>{a.date}</span>
                                   <span>{a.arrival_qty} {i18n.t("qty_metric")}</span>
                                 </div>
                               else return ''
                             })
                           }

                         </div>
                       </td>
                       <td style={{textAlign: "center", position: "relative"}}>
                         {pt_qty} {i18n.t("qty_metric")}
                       </td>
                     </tr>
                   })

               }
               </tbody>
             </table>
           </div>
           { ! storage.me.seller_mode && ! storage.me.seller_price_mode &&
           <div className="selected-variants-wrapper">
             {
                 this.state.selected_variants.length > 0 &&
                 <table className="variants-selected-header">

                   <thead>
                   <tr>
                     <th colSpan="5" className="table-price-border-right">{i18n.t("chosen_variants")}</th>
                   </tr>
                   <tr>
                     <th>{i18n.t("color")}</th>
                     <th>{i18n.t("size")}</th>
                     <th>{i18n.t("quantity")}</th>
                     {
                         config.picking &&
                         <th>{i18n.t('picking_charge')}</th>
                     }
                   </tr>
                   </thead>
                   <tbody>
                   {this.state.variants.map((variant) => {
                     return this.state.selected_variants.length > 0 && Object.keys(this.state.selected_variants).includes(variant.id) ?
                         <tr key={`selected-${variant.id}`} className={`variant-basket variant-${variant.id}`}>
                           {
                             variant.attributes.map((attribute) => {
                               return <td className="attribute-wrapper">
                                 <span style={{padding: "5px", display: "block"}}>{attribute.value}</span>
                               </td>
                             })
                           }
                           <td>{this.state.selected_variants[variant.id]} {i18n.t("qty_metric")}</td>
                           {
                               config.picking &&
                               <td>{this.getPicking(variant.id)} €</td>
                           }

                         </tr>
                         : ''


                   })
                   }

                   </tbody>
                 </table>
             }
             <div style={{textAlign: "right"}}>
               <h4>{i18n.t('price_per_item')}: <span
                   className="current-price-set">{this.state.current_price_set.price.toFixed(2)} €</span></h4>
               {
                   config.picking &&
                   <h4>{i18n.t('picking_charge')}: <span className="total-price">{this.calculatePickingPrice()} €</span>
                   </h4>
               }
               <h3>{i18n.t('total')}: <span className="total-price">{this.calculatePrice()} €</span></h3>
               <button
                   className="add-to-basket"
                   onClick={() => this.addToBasket()}
               >{i18n.t('add_to_basket')}</button>
             </div>
           </div>
           }
           <div className={`toast toast-price-change`} style={{display: this.state.showToast ? 'block' : 'none'}}>
             <FontAwesomeIcon
                 icon={faTimes}
                 className="btn-close"
                 onClick={() => this.closeToast()}
             />
             {i18n.t('price_category_changed_to')} {this.state.current_price_set.price} €
             ( {'>'}= {this.state.current_price_set.qty} {i18n.t("qty_metric")})
           </div>
           <div className={`toast toast-basket-change`}
                style={{display: this.state.showBasketToast ? 'block' : 'none'}}>
             <FontAwesomeIcon
                 icon={faTimes}
                 className="btn-close"
                 onClick={() => this.closeBasketToast()}
             />
             {i18n.t('basket_updated')}
           </div>
         </div>
     )
        : ''
  }
}

export default ProductBasket;